var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled/macro';
import { useState, } from 'react';
import AvatarImage from '@shared/ui/display/AvatarImage';
import { useAppSelector } from '@app/store/Hooks';
import IconText from '@shared/ui/icons/IconText';
import useAppNavigation from '@utils/hooks/useAppNavigation';
import { ProfileSwitcher } from '@features/profile/profile-switch';
// general sidebar width(213px) - all paddings
const NAME_MAX_WIDTH = 103;
const AvatarWrapper = styled.div(props => ({
    display: 'flex',
    flexShrink: 0,
    width: 24,
    height: 24,
    borderRadius: '50%',
    margin: 0,
    padding: 1,
    boxSizing: 'border-box',
    '&:hover': {
        outline: props.collapsed ? `2px solid ${props.theme.palette.primary.light}` : 'unset',
    },
    '&:active': {
        outline: props.collapsed ? `2px solid ${props.theme.customColors.shades.primaryMidLight}` : 'unset',
    },
}));
const defineSwitcherStyles = (collapsed = false, isHovered = false) => {
    if (collapsed) {
        return {
            padding: 0,
            opacity: isHovered ? 1 : 0,
            height: isHovered ? 62 : 0,
            width: isHovered ? 28 : 0,
            marginTop: isHovered ? '4px' : 0,
            boxSizing: 'border-box',
            justifyContent: 'center',
        };
    }
    return {
        opacity: 1,
        padding: 0,
        width: 62,
        justifyContent: 'center',
        height: 28,
    };
};
const ProfileSwitcherStyled = styled(ProfileSwitcher, { shouldForwardProp: propName => propName !== 'hovered' && propName !== 'flexDirection' })(props => (Object.assign({ transition: 'padding 0.55s, opacity 0.55s', flexDirection: props.flexDirection }, defineSwitcherStyles(props.collapsed, props.hovered))));
const Container = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    height: 'auto',
    boxSizing: 'border-box',
    transition: 'width 0.25s',
}));
const ContainerCollapsed = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}));
const IconTextStyled = styled(IconText, { shouldForwardProp: propName => propName !== 'collapsed' && propName !== 'hasPublicName' })(props => ({
    overflow: 'hidden',
    flexShrink: !props.collapsed ? 1 : 0,
    cursor: 'pointer',
    // TODO: fix IconText so that this props will affect it
    fontSize: 16,
    lineHeight: '24px',
    color: props.hasPublicName ?
        props.theme.palette.text.primary : props.theme.palette.text.secondary,
    fontWeight: 500,
    padding: `${props.theme.spacing_sizes.xs}px`,
    '&:hover': {
        backgroundColor: props.collapsed ? 'unset' : '#F8F9FA',
    },
    '& .MuiTypography-root': {
        display: props.collapsed ? 'none' : 'block',
        letterSpacing: props.collapsed ? 'unset' : '-0.176px',
        maxWidth: props.collapsed ? 'unset' : NAME_MAX_WIDTH,
    },
}));
const SwitcherContainer = styled.div(props => ({
    visibility: props.visible ? 'visible' : 'hidden',
}));
const SwitcherContainerCollapsed = styled(SwitcherContainer)(props => ({
    display: 'flex',
    justifyContent: 'center',
    height: props.visible ? 62 : 0,
    transition: 'height 0.55s',
}));
const SidebarProfileItem = ({ collapsed = false, }) => {
    var _a, _b;
    const { currentUserProfile } = useAppSelector(state => state.profile);
    const { user } = useAppSelector(state => state.user);
    const [isHovered, setIsHovered] = useState(false);
    const { navigate, routes } = useAppNavigation();
    const goToUserProfile = (event) => __awaiter(void 0, void 0, void 0, function* () {
        if (currentUserProfile) {
            event.stopPropagation();
            navigate(routes.MY_PROFILE);
        }
    });
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    return (_jsxs(Container, { collapsed: collapsed, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, children: [_jsxs(ContainerCollapsed, { collapsed: collapsed, children: [_jsx(IconTextStyled, { collapsed: collapsed, text: collapsed ? '' : (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.displayName) || `@${currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.username}`, hasPublicName: Boolean((_a = currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.displayName) === null || _a === void 0 ? void 0 : _a.length), IconComponent: (_jsx(AvatarWrapper, { collapsed: collapsed, children: _jsx(AvatarImage, { publicName: currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.displayName, imageUrl: (_b = currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.profilePicture) === null || _b === void 0 ? void 0 : _b.thumbnail, size: 'avatarSwitcher' }) })), onClick: goToUserProfile }), (user === null || user === void 0 ? void 0 : user.canSwitchProfile) && (_jsx(SwitcherContainerCollapsed, { visible: collapsed && isHovered, children: _jsx(ProfileSwitcherStyled, { collapsed: collapsed, flexDirection: 'column', hovered: isHovered }) }))] }), (user === null || user === void 0 ? void 0 : user.canSwitchProfile) && (_jsx(SwitcherContainer, { visible: !collapsed, children: _jsx(ProfileSwitcherStyled, { collapsed: collapsed, flexDirection: 'row', hovered: isHovered }) }))] }));
};
export default SidebarProfileItem;
